import Vue from "vue";

import configService from "@/services/configService";

import axios from "axios";


var EventBus = new Vue();

var messageService = {
    getMessageList: function (opts) {
        var apiUrl = configService.getApiUrl();
        return axios.get(apiUrl + "/message/getMessageList", opts);
    },
    removeMessage: function (id) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + "/message/deleteMessage", {
            id: id
        });
    },
    getMessageById: function (id) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + '/message/getMessageById', { id: id });
    },
    saveMessage: function (opts) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + '/message/saveMessage', opts);
    },
    notifyOnMessage: function (message) {
        EventBus.$emit('onMessage', {
            message : message,
        });
    },
    registerOnMessage: function (func) {
        EventBus.$on('onMessage', func);
    }
};

export default messageService;