var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-center" }, [
    _c(
      "div",
      { staticClass: "row justify-center full-width q-pa-lg" },
      [
        _c("q-btn", {
          attrs: { label: "generate board 2", color: "grey-7", size: "lg" },
          on: { click: _vm.generateBoard }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row q-pa-sm q-col-gutter-xs" },
      _vm._l(16, function(index) {
        return _c(
          "div",
          { key: index, staticClass: "col-3" },
          [
            _c("q-btn", {
              staticClass: "full-width q-pa-sm",
              attrs: { label: index, color: "grey-7", unelevated: "" }
            })
          ],
          1
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }