<template>
  <div class="login-wrapper border border-light">
    <form class="form-signin" @submit.prevent="login">
      <h2 class="form-signin-heading">Please sign in</h2>
      <!-- <div class="alert alert-danger" v-if="error">{{ error }}</div> -->
      <label for="inputEmail" class="sr-only">Email address</label>
      <input
        v-model="email"
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email address"
        required
        autofocus
      />
      <label for="inputPassword" class="sr-only">Password</label>
      <input
        v-model="password"
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
      />
      <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
      <div>
        <span @click="register">Register</span>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";

import authService from "@/services/authServices/authService";
import pubsubService from "@/services/commonUtils/pubsubService.js";


//   const io = require('socket.io-client');
//   var socket = null;
// var socketService = {
//   init : function(token) {
  
// if(socket) {
//  socket.destroy();
// }
//  socket = io('http://localhost:3000' {
//   extraHeaders: { Authorization: `Bearer ${your_jwt}` }
// });
// console.log("socketio");

// socket.on("connect", function () {
//   console.log("Made socket connection");

//   socket.on("hello", function (data) {
//     console.log("hey")
//     socket.emit("heyfromClient",{ bla : 123 });
//   });

//   socket.on("disconnect", () => {
//     // activeUsers.delete(socket.userId);
//     // io.emit("user disconnected", socket.userId);
//   });
// });
//   }
// }



export default {
  data() {
    return {
      email: "lma@lma.co.il",
      password: "123456"
    };
  },
  methods: {
    register() {
      this.$router.push({ path: "/auth/register" });
    },
    async login() {
 
      console.log(authService);
      var res = await authService.login({
        email: this.email,
        password: this.password
      });
      if(res.data.responseStatus == 1)
      {
        var token = res.data.responseData.token;
        if(token)
        {
          window.localStorage.setItem("token",token);
          pubsubService.publish("login");
          this.$router.push({ path: "/Main" });
        }
      
      }
      else
      {
        alert("login failed");
        console.log("login error")
        console.log(res);
      }


      
      
    }
  }
};
</script>

<style scoped>
body {
  background: #605b56;
}

.login-wrapper {
  background: #fff;
  width: 70%;
  margin: 12% auto;
}

.form-signin {
  max-width: 330px;
  padding: 10% 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>