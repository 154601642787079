import { render, staticRenderFns } from "./TestComponent.vue?vue&type=template&id=354c93b9&"
import script from "./TestComponent.vue?vue&type=script&lang=js&"
export * from "./TestComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/benmiller/Desktop/dev2/reactTest/test2/adminSiteToUpload/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('354c93b9', component.options)
    } else {
      api.reload('354c93b9', component.options)
    }
    module.hot.accept("./TestComponent.vue?vue&type=template&id=354c93b9&", function () {
      api.rerender('354c93b9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/components/TestComponent.vue"
export default component.exports