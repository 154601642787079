import axios from "axios";

var config = null;

var  getServerUrl = function() {
    if(nodeEnv == 'prod')
    {
        return "http://adminsite.the-toy-mall.com";
        
    }
    else {
        return "http://adminsite.the-toy-mall.com";;
    }
    //;
    return ;
}
var getApiUrl = function() {
    return getServerUrl() + '/api';
}

export default {
    getGoogleMapsApiKey : function() {
        const API_KEY = `AIzaSyCIDY509X06O1PVYkRow0DZ_Qc1l72s1QM`;
        return API_KEY;
    },
    getServerUrl : getServerUrl,
    getApiUrl : getApiUrl,
    loadConfig : async function(opts) {
        var apiUrl = getApiUrl();
        var res = axios.post(apiUrl + "/config/getConfig",opts).then(function (res) {
            console.log("configService loadConfig res :");
            console.log(res);
            if(res && res.data && res.data.responseStatus == 1)
            {
                config = res.data.responseData.config;
            }
            else
            {
                console.log("configService loadConfig error res");
            }
            return res;
          });
      return res;
       
    },
    getConfig : function() {
        return config;
    }
}

//module.exports = configService;