import configService from "@/services/configService";

import axios from "axios";


console.log("authService " + new Date().getTime());
var authService = {
    
    login : async function(opts) {
        var apiUrl = configService.getApiUrl();
        var res = await axios.post(apiUrl + "/auth/login",opts);
        if(res.data.responseStatus == 1)
        {
            var token = res.data.responseData.token;
            if(token)
            {
              window.localStorage.setItem("userData", JSON.stringify(res.data.responseData.userData));
              window.localStorage.setItem("token",token);
            }
        }
        return res;
    },
    logout : async function(opts) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userData");
    }, 
    register : async function(opts) {
        var apiUrl = configService.getApiUrl();
        return await axios.post(apiUrl + "/auth/register",opts);
    },
    sendRegisterEmail : async function(opts) {
        var apiUrl = configService.getApiUrl();
        return await axios.post(apiUrl + "/auth/sendRegisterEmail",opts);
    },
    getUserData : function() {
        return JSON.parse(window.localStorage.getItem("userData"));
    }
};

export default authService;