var PubsubService = function () {
    var self = this;
    var topics = {};
    var hop = topics.hasOwnProperty;
    var dname = "__$defaultName";

    var isEmptyObject = function (obj) {
        for (var name in obj) {
            if (obj.hasOwnProperty(name)) {
                return false;
            }
        }
        return true;
    }

    var forEachListener = function (topic, cb) {
        if (!hop.call(topics, topic)) return;
        for (var name in topics[topic]) {
            if (hop.call(topics[topic], name)) {
                var listenrs = topics[topic][name];
                for (var i = 0; i < listenrs.length; i++) {
                    cb(listenrs[i], name, i, topic);
                }
            }
        }
    }

    self.unsubscribe = function (topic, listener) {
        var isName = typeof listener === 'string';
        if (isName && (topics[topic] && topics[topic][listener])) {
            delete topics[topic][listener];
            if (isEmptyObject(topics[topic])) delete topics[topic]
        }
        else {
            forEachListener(topic, function (l, name, index) {
                if (listener === l.listener) {
                    topics[topic][name].splice(index, 1);
                    if (!topics[topic][name].length) {
                        delete topics[topic][name];
                        if (isEmptyObject(topics[topic])) delete topics[topic]
                    }
                }
            });
        }
    }

    self.subscribe = function (topic, listener, isOnes, name) {
        name = name || dname;
        if (!hop.call(topics, topic)) topics[topic] = {};
        if (!hop.call(topics[topic], name)) topics[topic][name] = [];
        topics[topic][name].push({
            isOnes: isOnes,
            listener: listener
        });
        return { remove: function () { self.unsubscribe(topic, listener); } }

    }
    self.publish = function (topic, data) {
        forEachListener(topic, function (l) {
            if (l.isOnes) { self.unsubscribe(topic, l.listener); }
            l.listener(data);
        })
    }
    self.clear = function (topic) {
        topic ? delete topics[topic] : topics = {}
    }
}

var pubsubService = new PubsubService();
export default pubsubService;