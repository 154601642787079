var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Dropdown",
        {
          attrs: {
            visible: _vm.isv,
            trigger: "custom",
            placement: "bottom-end"
          },
          on: {
            "on-clickoutside": _vm.onclickoutside,
            "on-visible-change": _vm.onvchange
          }
        },
        [
          _c("Input", {
            staticStyle: { width: "100%" },
            attrs: { placeholder: _vm.placeholder },
            on: { "on-change": _vm.queryChange },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "list" }, slot: "list" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.loadMore,
                    expression: "loadMore"
                  }
                ],
                staticStyle: {
                  "min-width": "200px",
                  "max-height": "100px",
                  overflow: "auto"
                },
                attrs: {
                  "infinite-scroll-disabled": "disabledLoadMore",
                  "infinite-scroll-distance": "10",
                  "infinite-scroll-immediate-check": "false"
                }
              },
              [
                _c(
                  "div",
                  [
                    _vm._t("content"),
                    _vm._v(" "),
                    _vm.loading ? _c("div", [_vm._v("loading")]) : _vm._e()
                  ],
                  2
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }