import { render, staticRenderFns } from "./HeaderComponent.vue?vue&type=template&id=182ccfc4&"
import script from "./HeaderComponent.vue?vue&type=script&lang=js&"
export * from "./HeaderComponent.vue?vue&type=script&lang=js&"
import style0 from "./HeaderComponent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/benmiller/Desktop/dev2/reactTest/test2/adminSiteToUpload/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('182ccfc4', component.options)
    } else {
      api.reload('182ccfc4', component.options)
    }
    module.hot.accept("./HeaderComponent.vue?vue&type=template&id=182ccfc4&", function () {
      api.rerender('182ccfc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/components/HeaderComponent.vue"
export default component.exports