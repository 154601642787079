import Vue from 'vue';
import axios from 'axios';
var EventBus = new Vue();
var loaderId = 'main';

axios.interceptors.request.use(function (config) {
  console.log(loaderId);
  loaderService.show(loaderService.getLoaderId());
  return config;
}, function (error) {
  loaderService.hide(loaderService.getLoaderId());
  console.log(error);
  //return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  loaderService.hide(loaderService.getLoaderId());
  return response;
}, function (error) {
  loaderService.hide(loaderService.getLoaderId());
  alert('network error');
  console.log(error);
  //return Promise.reject(error);
});


var isShow = false;
var t = null;

var loaderService = {
  setLoaderId: function (lid) {
    loaderId = lid;
  },
  getLoaderId: function () {
    return loaderId;
  },
  show(loaderId) {

    isShow = true;
    t = setTimeout(function () {
      EventBus.$emit('loaderIsShowChange', {
        loaderId: loaderId,
        isShow: isShow
      });
    }, 500);

  },
  hide(loaderId) {
    if (t) clearTimeout(t);
    isShow = false;
    EventBus.$emit('loaderIsShowChange', {
      loaderId: loaderId,
      isShow: isShow
    });
  },
  getIsShow() {
    return isShow;
  },
  onloaderIsShowChange: function (func) {
    EventBus.$on('loaderIsShowChange', func);
  }
}

export default loaderService;