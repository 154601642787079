import { array } from "@hapi/joi";

var loggerService = {
    log : function() {
        for(var arg in arguments)
        {
            console.log(arguments[arg])
        }
    },
    logRes : function() {
        var args = Array.prototype.slice.call(arguments);
        if(args && args.length)
        {
            args[1] = loggerService.getResLog(args[1]);
            loggerService.log.apply(this,args);
        }
        
    },
    getResLog : function(res) {
        if(res)
        {
            return res;
        }
        return null;
        
    }
}

export default loggerService;