<template>
  <div class="lma">
    <AutoCompComponent
      :loadMoreFunc="a_loadMore"
      :disabledLoadMore="a_disabledLoadMore"
      :dquery="a_query"
      :queryChangeFunc="a_queryChange"
      :loading="a_loading"
      :isOpen="a_isOpen"
      @isOpenChange="a_isOpen = $event"
      placeholder="Search"
    >
      <div slot="content" style="width:400px">
        <div @click="goTo(item)" v-for="item in a_data" :key="item.name">
          <div class="ivu-select-item">
            <div v-if="item.eType == 1">job : {{item.name}}</div>
            <div v-if="item.eType == 2">customer : {{item.name}} {{item.phone}}</div>
          </div>
        </div>
      </div>
    </AutoCompComponent>
  </div>
</template>

<script>
import Vue from "vue";
import debounce from "debounce";
import AutoCompComponent from "./AutoCompComponent";
Vue.component("AutoCompComponent", AutoCompComponent);

import commonUtil from "@/services/commonUtils/commonUtil";
import loggerService from "@/services/commonUtils/loggerService";
import nameValueListService from "@/services/nameValueListService";
import gSearchService from "@/services/gSearchService";

var autoCompPaganingMixing = function (opt) {
  var name = opt.name;
  //var nameUpper = toUpper(name);
  return {
    data() {
      return {
        [name + "_pageCount"]: opt.pageCount || 20,
        [name + "_page"]: opt.page || 0,
        [name + "_query"]: "",
        [name + "_data"]: [],
        [name + "_disabledLoadMore"]: false,
        [name + "_loading"]: false,
        [name + "_isOpen"]: false,
      };
    },
    methods: {
      [name + "_loadMore"]: debounce(async function (ff) {
        this[name + "_disabledLoadMore"] = true;
        this[name + "_loading"] = true;
        //aaa
        var isComplete = opt.loadData
          ? await opt.loadData()
          : await this[name + "_loadData"]({
              page: this[name + "_page"],
              pageCount: this[name + "_pageCount"],
              query: this[name + "_query"],
            });
        if (!isComplete) {
          this[name + "_disabledLoadMore"] = false;
        }
        this[name + "_page"]++;
        this[name + "_loading"] = false;
      }, 500),
      [name + "_queryChange"]: function (query) {
        this[name + "_query"] = query;
        this[name + "_page"] = 0;
        this[name + "_data"] = [];
        this[name + "_loadMore"]("queryChange");
      },
    },
    created() {},
  };
};

export default {
  data() {
    return {
      model13: "",
      options1: [],
      loading1: false,
    };
  },
  mixins: [autoCompPaganingMixing({ name: "a" })],
  methods: {
    goTo: function (item) {
      if (item.eType == 1) {
        this.$router.push({ path: "/jobEdit", query: { id: item.id } });
      } else if (item.eType == 2) {
        this.$router.push({ path: "/customerEdit", query: { id: item.id } });
      }
      this.a_query = "";
      this.a_isOpen = false;
    },
    a_loadData: async function (ev) {
      var self = this;
      if (self.a_query !== "") {
        this.loading1 = true;
        var res = await gSearchService.getGSearchList({
          params: {
            search: self.a_query,
          },
        });
        this.loading1 = false;
        var gsearchList = null;
        if (res && res.data && res.data.responseStatus == 1) {
          gsearchList = res.data.responseData.gSearchList;
          var list = [].concat(gsearchList.customerList, gsearchList.jobList);
          console.log("result :");
          console.log(self.a_query);
          console.log(list);
          var l = list;
          // var l = list
          //     .filter((i) => i.name.indexOf(self.a_query) > -1)
          //     .slice(
          //       self.a_page * self.a_pageCount,
          //       self.a_page * self.a_pageCount + self.a_pageCount
          //     );
          if (l.length && self.a_query == ev.query) {
            self.a_data = self.a_data.concat(l);
          }

          //return l.length ? false : true;
          return true;

          console.log("loding " + i);
        }
      }
      return true;
    },

    remoteMethod1: debounce(async function (query) {
      var self = this;
      if (query !== "") {
        this.loading1 = true;
        var res = await gSearchService.getGSearchList({
          params: {
            search: query,
          },
        });
        this.loading1 = false;
        var gsearchList = null;
        if (res && res.data && res.data.responseStatus == 1) {
          gsearchList = res.data.responseData.gSearchList;
          this.options1 = [].concat(
            gsearchList.customerList,
            gsearchList.jobList
          );
        }
      }
    }),
  },
  async created() {
    console.log("gSearch created");
  },
};
</script>

<style>
</style>
