var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lma" },
    [
      _c(
        "AutoCompComponent",
        {
          attrs: {
            loadMoreFunc: _vm.a_loadMore,
            disabledLoadMore: _vm.a_disabledLoadMore,
            dquery: _vm.a_query,
            queryChangeFunc: _vm.a_queryChange,
            loading: _vm.a_loading,
            isOpen: _vm.a_isOpen,
            placeholder: "Search"
          },
          on: {
            isOpenChange: function($event) {
              _vm.a_isOpen = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "400px" },
              attrs: { slot: "content" },
              slot: "content"
            },
            _vm._l(_vm.a_data, function(item) {
              return _c(
                "div",
                {
                  key: item.name,
                  on: {
                    click: function($event) {
                      _vm.goTo(item)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "ivu-select-item" }, [
                    item.eType == 1
                      ? _c("div", [_vm._v("job : " + _vm._s(item.name))])
                      : _vm._e(),
                    _vm._v(" "),
                    item.eType == 2
                      ? _c("div", [
                          _vm._v(
                            "customer : " +
                              _vm._s(item.name) +
                              " " +
                              _vm._s(item.phone)
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            })
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }