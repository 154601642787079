import configService from "@/services/configService";

import axios from "axios";


var userService = {
    getUserList : function(opts) {
        var apiUrl = configService.getApiUrl();
        return axios.get(apiUrl + "/user/getUserList",opts);
    },
    removeUser : function(id) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + "/user/deleteUser", {
            id: id
          });
    },
    getUserById : function(id) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + '/user/getUserById',{ id : id });
    },
    saveUser : function(opts) {
        var apiUrl = configService.getApiUrl();
        return axios.post(apiUrl + '/user/saveUser',opts);
    }
};

export default userService;