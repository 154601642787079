const cloneDeep = require('clone-deep');

var commonUtil = {
    format: function (str, ...args) {
        for (var k in args) {
            str = str.replace(new RegExp("\\{" + k + "\\}", 'g'), args[k]);
        }
        return str
    },
    groupBy: function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[key(x)] = rv[key(x)] || []).push(x);
            return rv;
        }, {});
    },
    unique: function (arr) {
        var arrUnique = arr.filter((item, index, self) => arr.indexOf(item) === index);
        return arrUnique;
    },
    sortBy: function (arr, key) {

    },
    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return true;
    },
    assign: function (target) {
     // if(typeof Object.assign === 'function')
        // {
        //     return Object.assign.apply()
        // }
        var onlyExistsProps = arguments[arguments.length - 1] === true;
        var o = commonUtil.isEmpty(target) ? arguments[1] : target;
        if (target === null || target === undefined) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

     
        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource !== null && nextSource !== undefined && nextSource !== true && nextSource !== false ) {
                for (var nextKey in nextSource) {
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey) && 
                    (!onlyExistsProps || (Object.prototype.hasOwnProperty.call(o, nextKey)))) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    },
    cloneDeep : cloneDeep
    // assign: function (target) {
    //     // if(typeof Object.assign === 'function')
    //     // {
    //     //     return Object.assign.apply()
    //     // }
    //     if (target === null || target === undefined) {
    //         throw new TypeError('Cannot convert undefined or null to object');
    //     }

    //     var to = Object(target);

    //     for (var index = 1; index < arguments.length; index++) {
    //         var nextSource = arguments[index];

    //         if (nextSource !== null && nextSource !== undefined) {
    //             for (var nextKey in nextSource) {
    //                 // Avoid bugs when hasOwnProperty is shadowed
    //                 if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
    //                     to[nextKey] = nextSource[nextKey];
    //                 }
    //             }
    //         }
    //     }
    //     return to;
    // }
    ,dateFormat : function(date, patternStr) {
        function twoDigitPad(num) {
            return num < 10 ? "0" + num : num;
        }
        var monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
          ];
          var dayOfWeekNames = [
            "Sunday", "Monday", "Tuesday",
            "Wednesday", "Thursday", "Friday", "Saturday"
          ];
       
              if (!patternStr) {
                  patternStr = 'M/d/yyyy';
              }
              var day = date.getDate(),
                  month = date.getMonth(),
                  year = date.getFullYear(),
                  hour = date.getHours(),
                  minute = date.getMinutes(),
                  second = date.getSeconds(),
                  miliseconds = date.getMilliseconds(),
                  h = hour % 12,
                  hh = twoDigitPad(h),
                  HH = twoDigitPad(hour),
                  mm = twoDigitPad(minute),
                  ss = twoDigitPad(second),
                  aaa = hour < 12 ? 'AM' : 'PM',
                  EEEE = dayOfWeekNames[date.getDay()],
                  EEE = EEEE.substr(0, 3),
                  dd = twoDigitPad(day),
                  M = month + 1,
                  MM = twoDigitPad(M),
                  MMMM = monthNames[month],
                  MMM = MMMM.substr(0, 3),
                  yyyy = year + "",
                  yy = yyyy.substr(2, 2)
              ;
              // checks to see if month name will be used
              patternStr = patternStr
                .replace('hh', hh).replace('h', h)
                .replace('HH', HH).replace('H', hour)
                .replace('mm', mm).replace('m', minute)
                .replace('ss', ss).replace('s', second)
                .replace('S', miliseconds)
                .replace('dd', dd).replace('d', day)
                
                .replace('EEEE', EEEE).replace('EEE', EEE)
                .replace('yyyy', yyyy)
                .replace('yy', yy)
                .replace('aaa', aaa);
              if (patternStr.indexOf('MMM') > -1) {
                  patternStr = patternStr
                    .replace('MMMM', MMMM)
                    .replace('MMM', MMM);
              }
              else {
                  patternStr = patternStr
                    .replace('MM', MM)
                    .replace('M', M);
              }
              return patternStr;
          
         
    },
    partition: function (arr, p) {
        var res = [[], []];
        for (var i = 0; i < arr.length; i++) {
          var yes = p(arr[i]);
          if (yes) {
            res[0].push(arr[i]);
          }
          else {
            res[1].push(arr[i]);
          }
        }
        return res;
      },
      findIndex : function(arr,p) {

          for(var i =0; i < arr.length ; i++)
          {
              var yes = p(arr[i]);
              if(yes)
              {
                  return i;
              }
          }
          return -1;
      },
      chunk: function () {
    
      },
      filterProps: function (o,filter,cols) {
        var lma = Object.keys(o)
          .filter(key => filter(key))
        //   .filter(key => { 
        //     var lma2 =  filter.map(c => c.title).includes(p);
        //     return lma2;
        //   })
          
          .reduce((obj, key) => {
            obj[key] = o[key]
            return obj;
          }, {});
          
          if(cols && cols.length)
          {
            for(var c in cols)
            {
              if(!lma.hasOwnProperty(cols[c]))
              {
                  lma[cols[c]] = undefined;
              }
            }
          }
          return lma;
      },
      walkObj: function (obj, c) {

        var path = [];
        function innerwalkObj(obj, c, p) {
          if (Object.prototype.toString.call(obj) === "[object Object]") {
            var lma = c("object", obj, p, path);
            if(lma)
            {
              for (var prop in obj) {
                path.push(prop);
                innerwalkObj(obj[prop], c, prop);
                path.pop();
              }
            }
           
          }
          // else if (Object.prototype.toString.call(obj) === "[object Array]") {
          //   c("array", obj, p);
          //   for (var i = 0; i < obj.length; i++) {
          //     path.push(i);
          //     walkObj(obj[i], c, i);
          //     path.pop();
          //   }
          // }
          else {
            c("prop", obj, p, path);
          }
        }
        innerwalkObj(obj,c)
      },
      
}

export default commonUtil;