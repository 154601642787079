import configService from "@/services/configService";

import axios from "axios";


var gSearchService = {
    getGSearchList : function(opts) {
        var apiUrl = configService.getApiUrl();
        return axios.get(apiUrl + "/gSearchRoutes/getGSearchList",opts);
    },
};

export default gSearchService;