var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "Split",
        {
          attrs: { min: "200px" },
          model: {
            value: _vm.split1,
            callback: function($$v) {
              _vm.split1 = $$v
            },
            expression: "split1"
          }
        },
        [
          _c(
            "div",
            { staticClass: "side", attrs: { slot: "left" }, slot: "left" },
            [
              _c(
                "div",
                { staticClass: "sideMenuWrapper" },
                [
                  _c(
                    "Menu",
                    {
                      ref: "menu",
                      attrs: { activeName: _vm.$route.name },
                      on: { "on-select": _vm.menuItemSelected }
                    },
                    [
                      _c("MenuItem", { attrs: { name: "home" } }, [
                        _vm._v("Home")
                      ]),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Job" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Job")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "jobList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "jobEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Customer" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Customer")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "customerList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "customerEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Insurance" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Insurance")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "insuranceList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "insuranceEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Tech" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Tech")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "techList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "techEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Task" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Task")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "taskList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "taskEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "User" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("User")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "userList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "userEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Payment" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Payment")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "paymentList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "paymentEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Csr" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Csr")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "csrList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "csrEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Location" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Location")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "locationList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "locationEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "SaleSource" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("SaleSource")
                          ]),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "saleSourceList" } },
                            [_vm._v("List")]
                          ),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "saleSourceEdit" } },
                            [_vm._v("Add/Edit")]
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "SaleRep" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("SaleRep")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "saleRepList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "saleRepEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "JobPart" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("JobPart")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "jobPartList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "jobPartEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "CommercialCustomer" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("CommercialCustomer")
                          ]),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "commercialCustomerList" } },
                            [_vm._v("List")]
                          ),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "commercialCustomerEdit" } },
                            [_vm._v("Add/Edit")]
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Invoice" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Invoice")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "invoiceList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "invoiceEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "PurchaseOrder" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("PurchaseOrder")
                          ]),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "purchaseOrderList" } },
                            [_vm._v("List")]
                          ),
                          _vm._v(" "),
                          _c(
                            "MenuItem",
                            { attrs: { name: "purchaseOrderEdit" } },
                            [_vm._v("Add/Edit")]
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Company" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Company")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "companyList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "companyEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "Submenu",
                        { attrs: { name: "Media" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("Media")]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "mediaList" } }, [
                            _vm._v("List")
                          ]),
                          _vm._v(" "),
                          _c("MenuItem", { attrs: { name: "mediaEdit" } }, [
                            _vm._v("Add/Edit")
                          ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("MenuItem", { attrs: { name: "schedule" } }, [
                        _vm._v("Schedule")
                      ]),
                      _vm._v(" "),
                      _c("MenuItem", { attrs: { name: "settings" } }, [
                        _vm._v("Settings")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", attrs: { slot: "right" }, slot: "right" },
            [
              _c("Loader", { attrs: { loaderId: "main" } }),
              _vm._v(" "),
              _c("HeaderComponent"),
              _vm._v(" "),
              _c("router-view", { key: _vm.$route.fullPath })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }