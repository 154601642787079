import Vue from 'vue'
import Router from 'vue-router'

import TestComponent from '@/components/TestComponent';

import MainComponent from '@/components/MainComponent';
import HomeComponent from '@/components/HomeComponent';
import LoginComponent from '@/components/AuthComponents/LoginComponent';
import RegisterComponent from '@/components/AuthComponents/RegisterComponent';

//ag import list and edit component
const CustomerListComponent = () => import(/* webpackChunkName: "CustomerListComponent" */'@/components/CustomerListComponent');
const CustomerEditComponent = () => import(/* webpackChunkName: "CustomerEditComponent" */'@/components/CustomerEditComponent');
const TaskListComponent = () => import(/* webpackChunkName: "TaskListComponent" */'@/components/TaskListComponent');
const TaskEditComponent = () => import(/* webpackChunkName: "TaskEditComponent" */'@/components/TaskEditComponent');
const UserListComponent = () => import(/* webpackChunkName: "UserListComponent" */'@/components/UserListComponent');
const UserEditComponent = () => import(/* webpackChunkName: "UserEditComponent" */'@/components/UserEditComponent');
const JobListComponent = () => import(/* webpackChunkName: "JobListComponent" */'@/components/JobListComponent');
const JobEditComponent = () => import(/* webpackChunkName: "JobEditComponent" */'@/components/JobEditComponent');
const InsuranceListComponent = () => import(/* webpackChunkName: "InsuranceListComponent" */'@/components/InsuranceListComponent');
const InsuranceEditComponent = () => import(/* webpackChunkName: "InsuranceEditComponent" */'@/components/InsuranceEditComponent');
const TechListComponent = () => import(/* webpackChunkName: "TechListComponent" */'@/components/TechListComponent');
const TechEditComponent = () => import(/* webpackChunkName: "TechEditComponent" */'@/components/TechEditComponent');
const PaymentListComponent = () => import(/* webpackChunkName: "PaymentListComponent" */'@/components/PaymentListComponent');
const PaymentEditComponent = () => import(/* webpackChunkName: "PaymentEditComponent" */'@/components/PaymentEditComponent');
const CsrListComponent = () => import(/* webpackChunkName: "CsrListComponent" */'@/components/CsrListComponent');
const CsrEditComponent = () => import(/* webpackChunkName: "CsrEditComponent" */'@/components/CsrEditComponent');
const LocationListComponent = () => import(/* webpackChunkName: "LocationListComponent" */'@/components/LocationListComponent');
const LocationEditComponent = () => import(/* webpackChunkName: "LocationEditComponent" */'@/components/LocationEditComponent');
const SaleSourceListComponent = () => import(/* webpackChunkName: "SaleSourceListComponent" */'@/components/SaleSourceListComponent');
const SaleSourceEditComponent = () => import(/* webpackChunkName: "SaleSourceEditComponent" */'@/components/SaleSourceEditComponent');
const SaleRepListComponent = () => import(/* webpackChunkName: "SaleRepListComponent" */'@/components/SaleRepListComponent');
const SaleRepEditComponent = () => import(/* webpackChunkName: "SaleRepEditComponent" */'@/components/SaleRepEditComponent');
const JobPartListComponent = () => import(/* webpackChunkName: "JobPartListComponent" */'@/components/JobPartListComponent');
const JobPartEditComponent = () => import(/* webpackChunkName: "JobPartEditComponent" */'@/components/JobPartEditComponent');
const CommercialCustomerListComponent = () => import(/* webpackChunkName: "CommercialCustomerListComponent" */'@/components/CommercialCustomerListComponent');
const CommercialCustomerEditComponent = () => import(/* webpackChunkName: "CommercialCustomerEditComponent" */'@/components/CommercialCustomerEditComponent');
const InvoiceListComponent = () => import(/* webpackChunkName: "InvoiceListComponent" */'@/components/InvoiceListComponent');
const InvoiceEditComponent = () => import(/* webpackChunkName: "InvoiceEditComponent" */'@/components/InvoiceEditComponent');
const PurchaseOrderListComponent = () => import(/* webpackChunkName: "PurchaseOrderListComponent" */'@/components/PurchaseOrderListComponent');
const PurchaseOrderEditComponent = () => import(/* webpackChunkName: "PurchaseOrderEditComponent" */'@/components/PurchaseOrderEditComponent');
const CompanyListComponent = () => import(/* webpackChunkName: "CompanyListComponent" */'@/components/CompanyListComponent');
const CompanyEditComponent = () => import(/* webpackChunkName: "CompanyEditComponent" */'@/components/CompanyEditComponent');
//ag import list and edit component end

const MediaListComponent = () => import(/* webpackChunkName: "MediaListComponent" */'@/components/MediaListComponent');
const MediaEditComponent = () => import(/* webpackChunkName: "MediaEditComponent" */'@/components/MediaEditComponent');

const ScheduleComponent = () => import(/* webpackChunkName: "ScheduleComponent" */'@/components/ScheduleComponent');
const SettingsComponent = () => import(/* webpackChunkName: "SettingsComponent" */'@/components/SettingsComponent');

Vue.use(Router);

var router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/Test',
      name: 'Test',
      component: TestComponent
    },
    {
      path: '/auth/login',
      name: 'Login',
      component: LoginComponent
    },
    {
      path: '/auth/register',
      name: 'Register',
      component: RegisterComponent
    },
    {
      path: '/',
      //name: 'MainComponent',
      component: MainComponent,
      children: [
        {
          name: "home",
          path: 'home',
          component: HomeComponent
        }, 
        {
          name: "m",
          path: '',
          redirect: { name: 'home' }
        }, 
        
        //ag add to router
        {
          name: "techList",
          path: 'techList',
          component: TechListComponent
        }, {
          name: "techEdit",
          path: 'techEdit',
          component: TechEditComponent
        }, {
          name: "insuranceList",
          path: 'insuranceList',
          component: InsuranceListComponent
        }, {
          name: "insuranceEdit",
          path: 'insuranceEdit',
          component: InsuranceEditComponent
        }, {
          name: "jobList",
          path: 'jobList',
          component: JobListComponent
        }, {
          name: "jobEdit",
          path: 'jobEdit',
          component: JobEditComponent
        }, {
          name: "customerList",
          path: 'customerList',
          component: CustomerListComponent
        }, {
          name: "customerEdit",
          path: 'customerEdit',
          component: CustomerEditComponent
        },
        {
          name: "taskList",
          path: 'taskList',
          component: TaskListComponent
        },
        {
          name: "taskEdit",
          path: 'taskEdit',
          component: TaskEditComponent
        },
        {
          name: "userList",
          path: 'userList',
          component: UserListComponent
        },
        {
          name: "userEdit",
          path: 'userEdit',
          component: UserEditComponent
        },
        {
          name: "paymentList",
          path: 'paymentList',
          component: PaymentListComponent
        }, {
          name: "paymentEdit",
          path: 'paymentEdit',
          component: PaymentEditComponent
        },
        {
          name: "csrList",
          path: 'csrList',
          component: CsrListComponent
        }, {
          name: "csrEdit",
          path: 'csrEdit',
          component: CsrEditComponent
        },
        {
          name: "locationList",
          path: 'locationList',
          component: LocationListComponent
        }, {
          name: "locationEdit",
          path: 'locationEdit',
          component: LocationEditComponent
        },
        {
          name: "saleRepList",
          path: 'saleRepList',
          component: SaleRepListComponent
        }, {
          name: "saleRepEdit",
          path: 'saleRepEdit',
          component: SaleRepEditComponent
        },
        {
          name: "saleSourceList",
          path: 'saleSourceList',
          component: SaleSourceListComponent
        }, {
          name: "saleSourceEdit",
          path: 'saleSourceEdit',
          component: SaleSourceEditComponent
        }, {
          name: "jobPartList",
          path: 'jobPartList',
          component: JobPartListComponent
        }, {
          name: "jobPartEdit",
          path: 'jobPartEdit',
          component: JobPartEditComponent
        },
        {
          name: "commercialCustomerList",
          path: 'commercialCustomerList',
          component: CommercialCustomerListComponent
        }, {
          name: "commercialCustomerEdit",
          path: 'commercialCustomerEdit',
          component: CommercialCustomerEditComponent
        },
        {
          name: "invoiceList",
          path: 'invoiceList',
          component: InvoiceListComponent
        }, {
          name: "invoiceEdit",
          path: 'invoiceEdit',
          component: InvoiceEditComponent
        },
   {
         name : "purchaseOrderList",
         path: 'purchaseOrderList',
         component: PurchaseOrderListComponent
       },{
         name : "purchaseOrderEdit",
         path: 'purchaseOrderEdit',
         component: PurchaseOrderEditComponent
       },
        {
         name : "companyList",
         path: 'companyList',
         component: CompanyListComponent
       },{
         name : "companyEdit",
         path: 'companyEdit',
         component: CompanyEditComponent
       },
        //ag add to router end
        {
          name: "mediaList",
          path: 'mediaList',
          component: MediaListComponent
        },
        {
          name: "mediaEdit",
          path: 'mediaEdit',
          component: MediaEditComponent
        },
        {
          name: "schedule",
          path: 'schedule',
          component: ScheduleComponent
        },
        {
          name: "settings",
          path: 'settings',
          component: SettingsComponent
        },
      ]
    },
    { path: '*', redirect: '/' },
  ]
})

export default router;