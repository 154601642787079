import configService from '@/services/configService'

var nameValueConst = {
    EntityType: 1,
    IsAvilable: 103,
    CommisionType: 102,
    JobStatus: 5,
    IsActive: 101,
    MediaType: 4,
    PaymentType: 6,
    EachFlat : 7,
    ActionType : 8,
    UserType : 106,
    PurchaseOrderStatus : 107,
    TagType : 110,
    MessageTemplateType : 111,
    JobType : 112,
    BoolType : 113,
}

var entityTypesConst = {
    System : 1001,
    Acount : 1002,
    Company : 1003,
    User : 1004,
    Media : 1005,
    Job : 1,
    Tech : 2,
    Customer : 3,
    CommercialCustomer : 4,
    Invoice : 13,
}

var mediaTypeConst = {
    CompanyInspectionPdf : 1,
    CompanyInspectionImg : 2,
    InspectionSignatore : 3,
    CompanyInvoicePdf : 7,
    CompanyInvoiceImg : 5,
    InvoiceSignatore : 6,
    JobInspectionPdf : 8,
    InvoicePdf : 9,
    General : 4
}


var nameValueList = [
    {
        type: 1,
        nameValueList: [
            {
                name: "System",
                value: 1001
            },
            {
                name: "Acount",
                value: 1002
            },
            {
                name: "Company",
                value: 1003
            },
            {
                name: "User",
                value: 1004
            },
            {
                name: "Media",
                value: 1005
            },
            {
                name: "Job",
                value: 1
            },
            {
                name: "Tech",
                value: 2
            },
            {
                name: "Customer",
                value: 3
            },
            {
                name: "CommercialCustomer",
                value: 4
            }
        ]
    },
    {
        type: 103,
        nameValueList: [
            {
                name: "Available",
                value: 1
            },
            {
                name: "NotAvailable",
                value: 2
            }
        ]
    },
    {
        type: 102,
        nameValueList: [
            {
                name: "%",
                value: 1
            },
            {
                name: "$",
                value: 2
            }
        ]
    },
    {
        type: 5,
        nameValueList: [
            {
                name: "Null",
                value: 1
            },
            {
                name: "Quote",
                value: 2
            },
            {
                name: "WorkOrder",
                value: 3
            },
            {
                name: "Invoice",
                value: 4
            }
        ]
    },
    {
        type: 101,
        nameValueList: [
            {
                name: "Active",
                value: 1
            },
            {
                name: "NotActive",
                value: 2
            }
        ]
    },
    {
        type: 4,
        nameValueList: [
            {
                name: "SystemInspectionPdf",
                value: 1
            },
            {
                name: "JobInspectionSignatore",
                value: 2
            },
            {
                name: "General",
                value: 3
            }
        ]
    },
    {
        type: 6,
        nameValueList: [
            {
                name: "BalanceDue",
                value: 1
            },
            {
                name: "Deductible",
                value: 2
            },
            {
                name: "Adjusment",
                value: 3
            }
        ]
    },
    {
        type : 7,
        nameValueList : [
            {
                name : "Each",
                value: 1
            },
            {
                name: "Flat",
                value: 2
            }
        ]
    }
]

console.log("nameValueList : ");
console.log(nameValueList);
var getNameValueList = function() {
    return configService.getConfig().nameValueList;
}
var getNameValueListByType = function (type) {
    var nv = getNameValueList().find(item => item.type == type);
    if(nv == null)
    {
        debugger;
    }
    return nv.nameValueList;
}

var nameValueListService = {
    getNameValueListByType : getNameValueListByType,
    getNameByValueAndType : function(value,type) {
        var nvList = getNameValueListByType(type);
        var name = null;
        if(nvList && nvList.length)
        {
            var nv = nvList.find(nv => nv.value == value);
            if(nv)
            {
                name = nv.name;
            }
        }
        return name;
    },
    getValueByNameAndType : function(name,type) {
        var nvList = getNameValueListByType(type);
        var value = null;
        if(nvList && nvList.length)
        {
            var nv = nvList.find(nv => nv.name == name);
            if(nv)
            {
                value = nv.value;
            }
        }
        return value;
    },
    nameValueConst : nameValueConst,
    entityTypesConst : entityTypesConst,
    mediaTypeConst : mediaTypeConst
}

// var nameValueListService = {
//     getNameValueListByType : function(type) {
//         if(type == 1)
//         {
//             return  [
//                     {
//                         name : 'availabe',
//                         value : 1
//                     },
//                     {
//                         name : 'not availabe',
//                         value : 2
//                     },
//                 ]
            
//         }
//         else if(type == 2)
//         {
//             return 
//                 [
//                     {
//                         name : 'Option1',
//                         value : 1
//                     },
//                     {
//                         name : 'Option2',
//                         value : 2
//                     },
//                     {
//                         name : 'Option3',
//                         value : 2
//                     },
//                 ]
            
//         }
//     }
// }

export default nameValueListService