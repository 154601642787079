var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "50px",
        "background-color": "white",
        "border-bottom": "1px solid #dcdee2"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { float: "left", padding: "10px" } },
        [
          _c(
            "Dropdown",
            { on: { "on-click": _vm.acountMenuItemClick } },
            [
              _c(
                "a",
                { attrs: { href: "javascript:void(0)" } },
                [
                  _vm._v("\n        Account\n        "),
                  _c("Icon", { attrs: { type: "ios-arrow-down" } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c("DropdownItem", { attrs: { name: "user" } }, [
                    _vm._v("User")
                  ]),
                  _vm._v(" "),
                  _c("DropdownItem", { attrs: { name: "settings" } }, [
                    _vm._v("Settings")
                  ]),
                  _vm._v(" "),
                  _c("DropdownItem", { attrs: { name: "logout" } }, [
                    _vm._v("Logout")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { float: "left", padding: "10px" } },
        [
          _c(
            "Dropdown",
            {
              attrs: { trigger: "hover" },
              on: { "on-visible-change": _vm.onvchange }
            },
            [
              _c("div", { staticStyle: { "padding-right": "15px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0px",
                      "border-radius": "50%",
                      width: "15px",
                      height: "15px",
                      background: "red",
                      "text-align": "center",
                      font: "13px Arial, sans-serif"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.messageList.filter(function(m) {
                          return (
                            _vm.lastDateGetMessageList == null ||
                            new Date(m.messageDate) > _vm.lastDateGetMessageList
                          )
                        }).length
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { attrs: { href: "javascript:void(0)" } },
                  [
                    _vm._v("\n          Notifications\n          "),
                    _c("Icon", { attrs: { type: "ios-arrow-down" } })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                _vm._l(_vm.messageList, function(m) {
                  return _c("DropdownItem", { key: m.id }, [
                    _c("div", [_vm._v(_vm._s(m.message))])
                  ])
                })
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { float: "right" } },
        [_c("GSearchComponent")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }